exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-machine-learning-perbedaan-machine-learning-dan-data-mining-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=E:/myprojects/gatsby_js/nafism05.github.io-code/blog/machine_learning/perbedaan_machine_learning_dan_data_mining.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-machine-learning-perbedaan-machine-learning-dan-data-mining-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-post-1-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=E:/myprojects/gatsby_js/nafism05.github.io-code/blog/post1.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-post-1-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-urutan-belajar-laravel-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=E:/myprojects/gatsby_js/nafism05.github.io-code/blog/urutan-belajar-laravel.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-urutan-belajar-laravel-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-web-development-html-paragraf-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=E:/myprojects/gatsby_js/nafism05.github.io-code/blog/web_development/html/paragraf.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-web-development-html-paragraf-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-web-development-laravel-laravel-praktis-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=E:/myprojects/gatsby_js/nafism05.github.io-code/blog/web_development/laravel/laravel_praktis.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-web-development-laravel-laravel-praktis-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-web-development-laravel-yang-dilakukan-setelah-clone-dari-github-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=E:/myprojects/gatsby_js/nafism05.github.io-code/blog/web_development/laravel/yang_dilakukan_setelah_clone_dari_github.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-web-development-laravel-yang-dilakukan-setelah-clone-dari-github-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-web-development-urutan-belajar-web-development-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=E:/myprojects/gatsby_js/nafism05.github.io-code/blog/web_development/urutan-belajar-web-development.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-web-development-urutan-belajar-web-development-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

